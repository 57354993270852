import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ManageActions = createActionGroup({
  source: 'Manage',
  events: {
    'Load Dashboard': emptyProps(),
    'Set Dashboard': props<{
      dashboard: any;
    }>(),

    'Create Mobility Order': props<{
      orders: { customer_id: string; budget: number }[];
    }>(),
    'Set Mobility Orders': props<{
      mobilityOrders: any;
    }>(),
    'Load Mobility Orders': emptyProps(),

    'Create Customers': props<{
      customers: {
        email: string;
        first_name: string;
        last_name: string;
      }[];
    }>(),
    'Edit Customers': props<{
      customers: {
        id: string;
        first_name: string;
        last_name: string;
      }[];
    }>(),
    'Set Customers': props<{
      customers: any;
    }>(),
    'Delete Customers': props<{ customer_ids: string[] }>(),
    'Load Customers': emptyProps(),

    'Load Company': emptyProps(),
    'Set Company': props<{
      company: any;
    }>(),
    'Update Company': props<{
      name: string;
      street: string;
      street_2: string;
      po_box: string;
      city: string;
      zip: string;
      country: string;
      cost_center: string;
      vat_number: string;
      person_responsible: string;
      person_responsible_email: string;
    }>(),
  },
});
